<template>
    <div v-if="word" class="about">
        <div class="animate__animated animate__fadeInUp">
            <div class="about-title">{{word.about.title[language]}}</div>
            <div class="about-text">
                <div class="text">{{word.about.us[language]}}</div>
                <div class="about-swiper">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div><img src="./../assets/about-1.png" alt=""></div>
                            </div>
                            <div class="swiper-slide">
                                <div><img src="./../assets/about-2.png" alt=""></div>
                            </div>
                            <div class="swiper-slide">
                                <div><img src="./../assets/about-3.png" alt=""></div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="animate__animated animate__fadeInUp">
            <div class="about-title">{{word.about.cultureTitle[language]}}</div>
            <div class="culture">
                <div class="about-img">
                    <img src="./../assets/culture.jpg" alt="">
                </div>
                <div class="culture-list">
                    <div>
                        <div><i class="iconfont">&#xe606;</i><span>{{word.about.passion[language]}}</span>，{{word.about.passionSubtitle[language]}}</div>
                        <div class="subtitle">
                            <div>{{word.about.passion1[language]}}</div>
                            <div>{{word.about.passion2[language]}}</div>
                            <div>{{word.about.passion3[language]}}</div>
                        </div>
                    </div>
                    <div>
                        <div><i class="iconfont">&#xf898;</i><span>{{word.about.efficient[language]}}</span>，{{word.about.efficientSubtitle[language]}}</div>
                        <div class="subtitle">
                            <div>{{word.about.efficient1[language]}}</div>
                            <div>{{word.about.efficient2[language]}}</div>
                            <div>{{word.about.efficient3[language]}}</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <i class="iconfont">&#xe7f1;</i><span>{{word.about.study[language]}}</span>，{{word.about.studySubtitle[language]}}</div>
                        <div class="subtitle">
                            <div>{{word.about.study1[language]}}</div>
                            <div>{{word.about.study2[language]}}</div>
                            <div>{{word.about.study3[language]}}</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <i class="iconfont">&#xe602;</i><span>{{word.about.innovate[language]}}</span>，{{word.about.innovateSubtitle[language]}}</div>
                        <div class="subtitle">
                            <div>{{word.about.innovate1[language]}}</div>
                            <div>{{word.about.innovate2[language]}}</div>
                            <div>{{word.about.innovate3[language]}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="about-title">{{word.about.branch[language]}}</div>
            <div style="width: 80%;margin: 0 auto;">
                <img style="width: 100%;" src="./../assets/map.png" alt="">
            </div>
        </div>
        <div class="map-tips">
            <img src="./../assets/etc.png" alt="">
            {{language == "CN" ? "筹备中" : "In Preparation"}}
        </div>
        <div class="about-address">
            <div class="about-add"></div>
            <div class="about-add-text">
                <div class="address">
                    <div class="add-info">
                        <div class="add-item">
                            <img src="./../assets/1.png" alt="">
                            <div>{{word.about.company[language]}}</div>
                        </div>
                        <div>
                            <i class="iconfont">&#xe88b;</i>
                            <div>service@code-notification.com</div>
                        </div>
                        <div>
                            <i class="iconfont">&#xe64e;</i>
                            <div>{{word.foot.company.specific[language]}}</div>
                        </div>
                    </div>
                </div>
                <div class="address">
                   
                </div>
                <div class="address">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<!-- eslint-disable -->
<script>
import 'animate.css';
import word from './../assets/json/language.json'
export default {
    name: 'About',
    components: {},
    data() {
        return {
            language: '',
            word: word,
        }
    },
    created() {
        this.language = localStorage.getItem('language')
    },
    method: {
    }
}
</script>

<style lang="scss" scoped>
.about {
    width: 100%;
    overflow: hidden;
}
.about-title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-size: 36px;
    text-align: center;
}
.about-text {
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
        max-width: 400px;
        line-height: 2;
        text-indent: 2em;
        margin-right: 50px;
    }
}
.about-img {
    width: 630px;
    max-width: 50%;
    padding: 20px 0;
    img {
        width: 100%;
    }
}
.culture {
    display: flex;
    justify-content: center;
    .culture-list {
        margin-left: 50px;
        margin-top: -10px;
        width: 500px;
        >div {
            height: 130px;
            font-size: 18px;
            i {
                font-size: 22px;
                margin-right: 10px;
                color: #108CCF;
                &+span {
                    color: #108CCF;
                    font-size: 24px;
                }
            }
            .subtitle {
                padding-left: 32px;
                margin-top: 6px;
                font-size: 14px;
                color: #999;
            }
        }
    }
}
.about-add-text {
    display: flex;
    .address {
        padding: 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-shrink: 0;
        flex-grow: 1;
        .add-info {
            >div {
                display: flex;
                align-items: top;
                margin: 20px 0;
                padding-left: 50px;
                max-width: 350px;
                &:first-of-type {
                    align-items: center;
                }
                .add-title {
                    margin-right: 10px;
                }
                >img {
                    margin-left: -10px;
                    margin-right: 10px;
                    width: 40px;
                }
                i {
                    font-size: 24px;
                    margin-right: 15px;
                }
            }
        }
    }
}
.map-tips {
    margin-top: -60px;
    font-size: 12px;
    text-align: right;
    color: #999999;
    margin-right: 40px;
    display: flex;
    align-items: center;
    float: right;
    img {
        width: 30px;
        margin-right: 10px;
    }
}
.about-address {
    position: relative;
}
.about-add {
    background-image: url("./../assets/bg.jpg");
    opacity: 0.3;
    background-size: 100%;
    background-color: transparent;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.about-swiper {
    width: 500px;
    position: relative;
    .swiper-container {
        width: 500px;
        height: 300px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .swiper-pagination {
        bottom: -30px;
        left: 0;
    }
}
</style>
